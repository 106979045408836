import React from "react"
import Helmet from "react-helmet"

const gtmSnippet = () =>
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${process.env.GTM_CONTAINER_ID}');`

const Meta = () => (
  <Helmet>
    <title>Locale</title>
    <script>{gtmSnippet()}</script>
  </Helmet>
)

export default Meta
